import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import withWidth from '@material-ui/core/withWidth';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '150px',
    marginLeft: '20px',
    zIndex: '100',
    '& a': {
        color: 'white',
        fontFamily: 'Barlow Semi Condensed',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& :hover': {
        backgroundColor: '#f8aa00',
    },
    '& button': {
        backgroundColor:'#3ebdb1',
        borderRadius: '0%',
        padding: '6px',
    },
    position: 'relative',
  },
  icon: {
    color: 'white',
    filter: 'drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5))'
  }
}));

const RedesSociales = (props) => {
    const classes = useStyles();
    let sizeLogos = "small";
    //Iconos no cambian tamaño cuando scroll
    //const isUpSmallBreakPoint = isWidthUp('sm', props.width);
    // if(props.onScroll) {
    //     sizeLogos = "small"
    // }
    // if(!isUpSmallBreakPoint) {
    //     sizeLogos = "small"
    // }

    return ( 
        <div className={classes.root} id="containerRedes">
            <IconButton aria-label="facebook" onClick={()=> window.open("https://www.facebook.com/minotamusicacademy", "_blank")} >
                <a>
                     <FacebookIcon fontSize={sizeLogos} className={classes.icon} />
                </a>
            </IconButton>
            <IconButton aria-label="instagram"  onClick={()=> window.open("https://www.instagram.com/minotamusicacademy", "_blank")}>
                <a>
                     <InstagramIcon fontSize={sizeLogos}  className={classes.icon} />
                </a>
            </IconButton>
            <IconButton  onClick={()=> window.open("https://www.youtube.com/channel/UCxOGhyC7jH-oPdsZX-Z9d9Q", "_blank")} color="primary" aria-label="youtube">
               <a>
                   <YouTubeIcon fontSize={sizeLogos}  className={classes.icon}/>
               </a>
            </IconButton>
        </div>
     );
}
 
export default withWidth()(RedesSociales);