import React, { useRef, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, Timestamp, updateDoc  } from 'firebase/firestore/lite';
import './ClientesPotenciales.css';
import {useSearchParams} from 'react-router-dom';
import Imgenlogo from '../../images/logoMinotaNegro.png';
import DropableAreas from './DropableAreas';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CloseIcon from '@material-ui/icons/CloseRounded';

function ClientesPotenciales(props) {
    const inputEl = useRef(null);
    const inputElOb = useRef(null);
    const [clientList, setClientes] = useState([]);
    const [modalData, setModalData] = useState({});
    const [filteredClients, setFilteredClients] = useState({});
    const [openMessage, setOpenMessage] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [choosedCampaing, setChoosedCampaing] = useState("NewClients-campanaPadres");
    var config = {
        apiKey: "AIzaSyCkIDcNumm96sb5sUqnqPgsTfDhh3NYzyw",
        authDomain: "minotaacademy2019.firebaseapp.com",
        databaseURL: "https://minotaacademy2019.firebaseio.com",
        projectId: "minotaacademy2019",
        storageBucket: "minotaacademy2019.appspot.com",
        messagingSenderId: "861408473010"
    };
    useState(()=>{
        if(searchParams.get("campainId")!=null) {
            setChoosedCampaing(searchParams.get("campainId"));
        }
    },[]);
    
    const app = initializeApp(config);
    const db = getFirestore(app);
    const campains = {
        'NewClients-campanaPadres':"FB/INSTA/PADRES/DIC/2021",
        'NewClients-campanaPadres1':"FB/INSTA/PADRES/ENE/2022",
        'NewClients-campanaPadres2':"FB/INSTA/PADRES/ABRIL/2022"
    };

    async function getClientes(db) {
        const clientes = collection(db, choosedCampaing);
        const clientesSnapshot = await getDocs(clientes);
        const clientsList = clientesSnapshot.docs.map(doc => { 
            return {...doc.data(), idElement: doc.id};
        });
        setClientes(clientsList);
        return clientsList;
    };

    async function updateNewData(db, id, status) {
        const washingtonRef = doc(db, choosedCampaing, id);
        await updateDoc(washingtonRef, {
            status
        });
    }

    async function updateNewDataObservation(db, id, observacion) {
        const washingtonRef = doc(db, choosedCampaing, id);
        await updateDoc(washingtonRef, {
            observacion
        });
    }
    
    function handleOnDragEnd(result) {
        console.log(result);
        if (!result.destination) return;

        let reorderedItem=[];
        // where i delete the item
        const areaToDelete = filteredClients[result.source.droppableId];
        [reorderedItem] = areaToDelete.splice(result.source.index, 1);
        const areatoAdd = filteredClients[result.destination.droppableId]??[];
        areatoAdd.splice(result.destination.index, 0, {...reorderedItem, status:result.destination.droppableId});
        setFilteredClients(prev =>{
            return {...prev, 
                [result.source.droppableId]: areaToDelete,
                [result.destination.droppableId]: areatoAdd
            }
        });
        updateNewData(db, result.draggableId, result.destination.droppableId);
    };

    const abrirModal = (nombre, email, celular, observacion, fecha, id, status) => {
        console.log({nombre, email, celular, observacion, fecha, abrir: true, id, status});
        setModalData({nombre, email, celular, observacion, fecha, abrir: true, id, status})
    };

   useEffect(()=>{
    getClientes(db);
   },[choosedCampaing]);

   useEffect(()=>{
       const clients = filteredClients[modalData.status]??[];
       const client =clients.filter((element) =>{return element.idElement === modalData.id});
       const indexClient = clients.indexOf(client[0]);
       const arrayClients =  [...clients];
       arrayClients[indexClient] = {...client[0], "observacion": modalData.observacion}   
        setFilteredClients(
        (prev) => {
            return {
                ...prev,
                [modalData.status]: arrayClients
            }
        } 
    );
    },[modalData]);

   useEffect(()=>{
    const data = clientList.sort(function(a, b){return b.fecha.seconds - a.fecha.seconds});
    setFilteredClients({});
    data.forEach((element)=> {
        if(element.status==="nuevoCliente"){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "nuevoCliente":prev.nuevoCliente? [
                            ...prev.nuevoCliente, 
                            element
                        ] : [element]
                    }
                } 
            );
        }
        if(element.status==="contactado"){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "contactado":prev.contactado? [
                            ...prev.contactado, 
                            element
                        ] : [element]
                    }
                } 
            );
        }
        if(element.status==="interesado"){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "interesado": prev.interesado ? [
                            ...prev.interesado, 
                            element
                        ] : [element]
                    }
                } 
            );
        }
        if(element.status==="noInteresado"){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "noInteresado":prev.noInteresado ?  [
                            ...prev.noInteresado, 
                            element
                        ] : [element]
                    }
                } 
            );
        }
        if(element.status==="enPrueba"){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "enPrueba":prev.enPrueba? [
                            ...prev.enPrueba, 
                            element
                        ] : [element]
                    }
                } 
            );
        }
        if(element.status==="convertido"){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "convertido":prev.convertido? [
                            ...prev.convertido, 
                            element
                        ]: [element]
                    }
                } 
            );
        }
        if(element.status==="noConvertido"){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "noConvertido":prev.noConvertido ? [
                            ...prev.noConvertido, 
                            element
                        ]: [element]
                    }
                } 
            );
        }
        if(element.status===undefined){
            setFilteredClients(
                (prev) => {
                    return {
                        ...prev,
                        "nuevoCliente": prev.nuevoCliente ? [
                            ...prev.nuevoCliente, 
                            element
                        ] : [element]
                    }
                } 
            );
        }     
    });
   },[clientList]);

   const choseCampaing = (campaing) => {
    setChoosedCampaing(campaing);
    setSearchParams({"campainId": campaing});
   };

   const enviarMensajeWhats = (celular) => {
        const message = inputEl.current.value;
        inputEl.current.value = "";
        setModalData(prev => {return {...prev, abrir: false}});
        setOpenMessage(false);
        window.open("https://api.whatsapp.com/send?phone=+57"+celular+"&text="+message);
   };
   const enviarMensajeObservacion = (id) => {
       const message = (new Date()).toDateString() + "!!" +inputElOb.current.value;
       inputElOb.current.value = "";
       const newDataObs = modalData.observacion ? [message, ...modalData.observacion] : [message];
       updateNewDataObservation(db,id,newDataObs);
       setModalData(prev => {return{...prev, "observacion": newDataObs}});
   };

  return (
    <div className="container-cp">
        <div className="img-cp">
            <img src={Imgenlogo} alt="logo minota" />
        </div>
        <h1 className="main-title-cp">Clientes Potenciales Minota</h1>
        <h1 className="main-title-leads">Leads Generados {clientList.length} - Campaña {campains[choosedCampaing]}</h1>
        <div className='main-campains'>
            {
                Object.keys(campains).map(campain =>{
                    return (<button onClick={()=>{choseCampaing(campain)}}>{campains[campain]}</button>)
                })
            }
        </div>
        <div className="dragableCotainer">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <DropableAreas 
                id={"nuevoCliente"}
                title={"Nuevos Clientes"}
                setModal={abrirModal}
                elementos={filteredClients?.nuevoCliente}
                totalElements={clientList.length}
            />
             <DropableAreas 
                id={"contactado"}
                title={"Contactado Whatsapp/LLamada"}
                setModal={abrirModal}
                elementos={filteredClients?.contactado}
                totalElements={clientList.length}
            />
             <DropableAreas 
                id={"interesado"}
                title={"Interesado"}
                setModal={abrirModal}
                elementos={filteredClients?.interesado}
                totalElements={clientList.length}
            />
             <DropableAreas 
                id={"noInteresado"}
                title={"No Interesado"}
                setModal={abrirModal}
                elementos={filteredClients?.noInteresado}
                totalElements={clientList.length}
            />
             <DropableAreas 
                id={"enPrueba"}
                title={"En prueba"}
                setModal={abrirModal}
                elementos={filteredClients?.enPrueba}
                totalElements={clientList.length}
            />
             <DropableAreas 
                id={"convertido"}
                title={"Convertido"}
                setModal={abrirModal}
                elementos={filteredClients?.convertido}
                totalElements={clientList.length}
            />
            <DropableAreas 
                id={"noConvertido"}
                title={"No Convertido"}
                setModal={abrirModal}
                elementos={filteredClients?.noConvertido}
                totalElements={clientList.length}
            />
            
          </DragDropContext>
        </div>
        {modalData.abrir &&
            <div className="modal-cp">
                <div className="modal-cont">
                <div className="close-button-cp"><CloseIcon onClick={()=>{setModalData(prev=>{return {...prev, abrir: false}})}}/></div>
                <div className="container-tarjeta">
                                        <div className="container-picture">
                                            <div className="characters-thumb">
                                            <img src={'/images/gary.png'} alt={`${modalData.nombre} Thumb`} />
                                            </div>
                                            <div>
                                                <p>Nombre:</p>
                                                <p className="elementSub">
                                                { modalData.nombre }
                                                </p>
                                            </div>
                                        </div>
                                        <p>E-mail:</p>
                                        <p className="elementSub">
                                            { modalData.email }
                                        </p>
                                        <p>Celular:</p>
                                        <div className="elementSub">
                                            { modalData.celular }
                                            <WhatsAppIcon onClick={()=>{setOpenMessage(!openMessage)}}style={{ fontSize: 18 }} />
                                            {openMessage && 
                                            <div className="input-cp">
                                                <input ref={inputEl} type="text"/>
                                                <button onClick={()=>enviarMensajeWhats(modalData.celular)}>Enviar</button>
                                            </div>
                                            }
                                        </div>
                                        <p>Observaciones:</p>
                                        <div className="input-cp">
                                            <input ref={inputElOb} type="text"/>
                                            <button onClick={()=>enviarMensajeObservacion(modalData.id)}>Enviar</button>
                                        </div>
                                        <div className="elementSub container-obs-cp">
                                            {modalData.observacion &&
                                                modalData.observacion.map((observacion, index) => {
                                                   return (<p key={index} className="elementSub"><span className="fecha-cp-inter">{observacion.split("!!")[0]+" "}</span>{observacion.split("!!")[1]}</p>)
                                                })
                                            }
                                        </div>
                                        <div className="fecha-cp">
                                            { (new Date(modalData.fecha.seconds * 1000)).toDateString() }
                                        </div>
                                    </div>
                </div>  
            </div>
        }
    </div>
  );
}

export default ClientesPotenciales;