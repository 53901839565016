import React from 'react';
import Home from './components/Home';
import Login from "./components/login/login";
import Curso from "./components/curso/curso";
import ClientesPotenciales from './components/clientesPotenciales/ClientesPotenciales';
import './App.css';
import PageNew from './components/pageWorking/pageNew';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams
} from "react-router-dom";
import LandingContainer from './components/landingPages/LandingContainer1';
import Felicitaciones from './components/landingPages/components/Felicitaciones';

function App() {
  
  return (
      <>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/felicitaciones" element={<Felicitaciones />} /> 
          <Route path="/pagos" element={<PageNew tipo={"pago"}/>} /> 
          <Route path="/login" element={<Login/>} /> 
          <Route path="/clientes-potenciales" element={<ClientesPotenciales />} /> 
          <Route path="/curso" element={<Curso/>} /> 
          <Route path="/galeria" element={<PageNew tipo={"galeria"}/>} />
          <Route path="/contenido-virtual" element={<PageNew tipo={"contenido virtual"}/>} />

          {//El contador solo podra funcionar bien para dias positivos y no funcionara conteos mayores a 99 dias 
          // El conteo se debe setear en unix time con una ruta parecida a esta 
          //
          }
          <Route path="/promo/:id" element={<LandingContainer />} />
          <Route path="/"  element={<Home />} />
        </Routes>
      </>
  );
}

export default App;
