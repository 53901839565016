import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ReactPixel from 'react-facebook-pixel';
import Fondo from '../../../images/landingPage1/FONDO_POP_UP.png';
import FondoBoton from '../../../images/landingPage1/mainButton.png';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, setDoc, addDoc, Timestamp } from 'firebase/firestore/lite';
import { useForm } from "react-hook-form";
import {
  useNavigate,
} from "react-router-dom";


function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundImage: 'url("'+Fondo+'")',
    maxWidth: '450px',
    backgroundSize: 'cover',
    backgroundPositionX: '-360px',
    width: '100%',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0, 3),
    '& .mainButtonModal': {
      backgroundImage: "url('"+FondoBoton+"')",
      backgroundSize: "cover",
      maxHeight: "103px",
      height: '21vw',
      margin: '10px',
      "-webkit-filter": "drop-shadow(0px 2px 3px yellow)"
    },
    '& h2': {
        color: '#e8e858',
        marginLeft: '20px',
        zIndex: '99',
        margin: '10px',
        marginRight: '102px',
        position: 'relative',
        fontWeight: '600',
        fontSize: '176%',
        fontFamily: 'Barlow Semi Condensed',
    }
  },
  fondoModal: {
    position: 'absolute',
    width: '750px',
    zIndex: '1',
    left: '-302px',
    top: '-7px'
  },
  red: {
    color: "yellow",
    marginLeft: '20px'
  },
  cuadroDeLlenar: {
    zIndex: '99',
    margin: '20px',
    marginTop: '100px',
    position: 'relative',
    '& input': {
       width: '100%',
       borderRadius: '100px',
       borderWidth: 'inherit',
       lineHeight: '34px',
       paddingLeft: '15px',
       marginTop: '10px',
       marginRight: '10px'

    },
    '& input[type="checkbox"]': {
      width: '18px',
      marginRight: '2px',
      marginLeft: '10px'
    },
    '& input[type="submit"]': {
      border: 'none',
      backgroundColor: 'transparent',
      paddingLeft: '0'
    },
   '& .buttonInscripcion': {
    backgroundColor: '#551998',
    borderWidth: '2px',
    borderColor: 'yellow',
    width: '70%',
    color: 'yellow',

    },
    '& .button-modal-landing':{
      color: 'yellow',
      background: 'purple',
      width: '160px',
      height: '70px'
    },
    '& label': {
    display: 'inlineBlock',
    width: '100%',
    color: 'white',
    }
  }
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const history = useNavigate();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  
  const onSubmit = data =>{

    async function setNewClient(nombre, email, celular) {
      //Este id se cambia para cada nueva campaña
      await addDoc(collection(db, "NewClients-campanaPadres2"), {
        nombre,
        email,
        celular,
        fecha: Timestamp.fromDate(new Date()),
        campana: "LP2"
      });
    };
    setNewClient(data.nombre, data.correo, data.celular);
    history('/felicitaciones');
  };
  
  var config = {
    apiKey: "AIzaSyCkIDcNumm96sb5sUqnqPgsTfDhh3NYzyw",
      authDomain: "minotaacademy2019.firebaseapp.com",
      databaseURL: "https://minotaacademy2019.firebaseio.com",
      projectId: "minotaacademy2019",
      storageBucket: "minotaacademy2019.appspot.com",
      messagingSenderId: "861408473010"
    };
  
  const app = initializeApp(config);
  const db = getFirestore(app);

// Get a list of cities from your database
async function getClientes(db) {
  const precios = collection(db, 'Precios');
  const citySnapshot = await getDocs(precios);
  const cityList = citySnapshot.docs.map(doc => console.log(doc.data()));
  return cityList;
}
  

  //console.log(watch("example")); // watch input value by passing the name of it
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Estimula el potencial de tus hijos y juntos descubramos su gran talento.</h2>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.cuadroDeLlenar}>
      {/* register your input into the hook by invoking the "register" function */}
      <input placeholder="Nombre" {...register("nombre",{ required: true, pattern: "[a-zA-Z0-9\s]+" })} />
      {errors.nombre && <span className={classes.red}>Un nombre es requerido</span>}
      
      {/* include validation with required or other standard HTML validation rules */}
      <input type="email" placeholder="Correo" {...register("correo", { required: true })} />
      {/* errors will return when field validation fails  */}
      {errors.correo && <span className={classes.red}>Un correo es requerido</span>}

      { /* include validation with required or other standard HTML validation rules */}
      <input type="number" placeholder="Celular" {...register("celular", { required: true, maxLength: 10 })} />
      {/* errors will return when field validation fails  */}
      {errors.celular && <span className={classes.red}>Un celular es requerido</span>}
      {errors.celular?.type=== "maxLength" && <span className={classes.red}>El celular tiene demasiados numeros</span>}
      { /* include validation with required or other standard HTML validation rules */}
      <label>< input type="checkBox" {...register("checkBox", { required: true })}/> 
      Acepto las politicas de privacidad. </label> 
      {/* errors will return when field validation fails  */}
      {errors.checkBox && <span className={classes.red}>Aceptar las politicas es necesario</span>}
      <input className="mainButtonModal" type="submit" value=""/>
    </form>
  
    <script>
      function OpenFelicitaciones(params) {
    <a href="pagina.html" target="_blank">Título del enlace</a>
        
      }
    </script>

    </div>
  );
  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={()=> {}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}