import React, {useState} from 'react';
import './PreguntasFrecuentes.css';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function PreguntasFrecuentes(props) {
    const [respuesta, setRespuesta] = useState();
    const [mostrarRespuesta, setMostrarRespuesta] = useState(false);
    const preguntasLista = {
        1:  {   "p": "¿Qué enseñan en Minota?",
                "r":"En MiNota enseñamos música, disciplinas físicas y arte.  • MÚSICA ADULTOS Y NIÑOS (9 años en adelante):  Guitarra eléctrica, Guitarra acústica, Batería, Percusión menor, Piano, Canto, Bajo eléctrico, Violín, Viola, Saxofón, Clarinete, Pre-Producción y Producción Musical, Teoría musical y Armonía. • MÚSICA NIÑOS (4 a 8 años): Iniciación musical, pre Piano, pre Guitarra, Ukelele, Canto, Violín. • DISCIPLINAS FÍSICAS (2 años en adelante): Pre Gimnasia Rítmica, Gimnasia Rítmica, pre Ballet, Ballet y Taller de Danza. • MÚSICA BABIES (8 meses en adelante): Estimulación musical, Iniciación musical, Gimnasia para niños."  
            },
        2: {    "p":"¿Desde que edad puedo entrar a Minota?",
                "r":"En MiNota tenemos clases para todas las edades, desde los más grandes hasta los más pequeños. Nuestros estudiantes pueden tomar clase desde los 8 meses en adelante."
        },
        3: {    "p":"¿Puedo entrar a estudiar un instrumento sin conocimiento previo?",
                "r":"¡Sí! en MiNota te brindaremos todos los conocimientos necesarios para que te conviertas en el artista de tus sueños."
        },
        4: {    "p":"¿Las clases en MiNota son Individuales o Grupales?",
                "r": "¡Nuestras clases de instrumento son personalizadas! Tenemos también clases de gimnasia rítmica, danza, ballet, iniciación y estimulación musical, que puedes elegir si quieres tomarla personalizada o grupal."
        },
        5: {    "p": "¿En MiNota qué modalidades de clase tienen?",
                "r":"Tenemos modalidad: Presencial, Virtual o A Domicilio, para que escojas lo que mejor se acomode a tiempo."
            },
        6: {    "p": "¿Tienen Planes de Pago?",
                "r":"Tenemos Paquetes por 4, por 8 y por 12 clases."
        },
        7: {    "p": "¿Tienen flexibilidad en los horarios?",
                "r":"¡Por supuesto! nos acomodamos a tus horarios. Estamos de lunes a sábado de 7:00 a.m. a 7:00 p.m."
        },
        8: {    "p": "¿Cuánto dura cada clase en MiNota?",
                "r":"Nuestras clases tienen una duración de 60 minutos."
        },
        9: {    "p": "¿Cuántas clases a la semana voy a tener?",
                "r":"Recomendamos iniciar con 1 clase a la semana, sin embargo, si deseas aumentar el número de tus clases después del primes, puedes hacerlo."
        },
        10: {   "p":"¿Dónde se encuentra ubicada la escuela?",
                "r":"Estamos ubicados en el Parque de la 93, específicamente en la Cra. 11 No. 93B-31 Local 6."
        },
        11: {   "p":"¿Tengo algún descuento si me inscribo a clases con un amigo o familiar?",
                "r":"¡Claro que sí! tenemos un Plan de Referidos, con el que siempre hay descuentos."
        },
        12: {   "p":"¿Los profesores de MiNota están certificados?",
                "r":"Claro que tenemos maestros certificados, también tenemos a tu disposición la reseña de cada uno de ellos para que conozcas mejor su carrera musical."
        }
    };

    const onClickPregunta = (id) => {
        console.log(id);
        setRespuesta( { ...preguntasLista[id], "id": id}  );
        setMostrarRespuesta(!mostrarRespuesta);
    };

    return (
        <div className="main-container-pf" id="preguntas">
            <h1>PREGUNTAS FRECUENTES</h1>
            <p>Da click en la pregunta y se mostrara la respuesta:</p>
        {!mostrarRespuesta ? 
            <div className="container-preguntas">
                {Object.keys(preguntasLista).map(pregunta => {
                    return (<div  key={pregunta} className="container-pregunta">
                        <h2 onClick={()=>onClickPregunta(pregunta)} className="title-pregunta">{pregunta + ". "+preguntasLista[pregunta]?.p}</h2>
                    </div>)
                    })
                }
            </div> : 
            (
                <div className="container-pregunta-respuesta fade-in">
                    <div className="container-pregunta-r-blanco">
                        <div  className="width-complete">
                        <div className="close-pr"><CloseRoundedIcon onClick={()=>setMostrarRespuesta(false)} /></div>
                            <h2 className="title-pregunta">{respuesta.id+". "+respuesta.p}</h2>
                            <p>{respuesta.r}</p>
                        </div>
                    </div>
                </div>
            )
        }
        </div>
    );
}

export default PreguntasFrecuentes;