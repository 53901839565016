    import React from 'react';
    import { Droppable, Draggable } from 'react-beautiful-dnd';
    import WhatsAppIcon from '@material-ui/icons/WhatsApp';
 
   
    function DropableAreas({id, title, elementos, setModal, totalElements}) {
        const largo = elementos ? elementos.length : 0;
        const areaRate = ((largo/totalElements)*100).toFixed(2);
        return (
            <Droppable droppableId={id}>
              {(provided) => (
                <div className="columnDrag">
                    <div className="titulo-clientes">{title + " (" +largo+")"}<span className="rate-color">{" (" +areaRate+"%)"}</span>{(title==="Convertido"?"     Meta(10%-20%)":"")}</div>
                    <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                    {elementos?.map(({idElement, fecha, nombre, email, celular, observacion, status}, index) => {
                        return (
                            <Draggable key={idElement} draggableId={idElement} index={index}>
                                {(provided) => (
                                <li  onClick={()=>setModal(nombre, email, celular, observacion, fecha, idElement, status)}ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div className="container-tarjeta">
                                        <div className="container-picture">
                                            <div className="characters-thumb">
                                            <img src={'/images/gary.png'} alt={`${nombre} Thumb`} />
                                            </div>
                                            <div>
                                                <p>Nombre:</p>
                                                <p className="elementSub">
                                                { nombre }
                                                </p>
                                            </div>
                                        </div>
                                        <p>E-mail:</p>
                                        <p className="elementSub">
                                            { email }
                                        </p>
                                        <p>Celular:</p>
                                        <p className="elementSub">
                                            { celular }
                                            <WhatsAppIcon style={{ fontSize: 18 }} />
                                        </p>
                                        <div className="fecha-cp">
                                            { (new Date(fecha.seconds * 1000)).toDateString() }
                                        </div>
                                    </div>
                                </li>
                                )}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                    </ul>
                </div>
              )}
            </Droppable>
        );
    }
    
    export default DropableAreas;