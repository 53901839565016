import React from 'react';
import './Planes.css';
import precios from '../../images/precios.jpeg'
const Planes = (props) => {
    return (
        <div id="planes-precios" className="container-planes">
            <h1>NUESTROS PLANES</h1>
            <h4>¡Tenemos planes increíbles para que te inscribas ahora mismo!</h4>
            <h4>!Paquetes Flexibles!</h4>
            <h4>¡Paquetes Fijos!</h4>
            <h4>¡Clases Online o en la Academia!</h4>
            <h1>Contactanos y Pregunta Ya por nuestros Precios!!!</h1>


            {/* <img src={precios}></img> */}
            {/* <div className="row-planes">
                <div className="column1-planes">
                    <div className="titulo-amarillo-planes">
                        CLASES PRESENCIALES
                    </div>
                    <div className="parrafo-planes">
                        Piano, Guitarra, Canto, Bajo, Batería, Percusión e Instrumento de Cuerda Frotada
                        <div className="description-planes">
                            <p>1 Mes</p>
                            <p>Paquete x 4 clases</p>
                            <p className="precio">$250.000</p>
                            <div className="vigencia-planes">Vigencia de 6 semanas</div>
                        </div>
                        <div className="description-planes description1-color">
                            <p>2 Meses</p>
                            <p>Paquete x 8 clases</p>
                            <p className="precio">$440.000</p>
                            <p className="precio-mes">Precio mes $220.000</p>
                            <div className="vigencia-planes">Vigencia de 12 semanas</div>
                        </div>
                        <div className="description-planes description2-color">
                            <p>3 Meses</p>
                            <p>Paquete x 12 clases</p>
                            <p className="precio">$600.000</p>
                            <p className="precio-mes">Precio mes $200.000</p>
                            <div className="vigencia-planes">Vigencia de 6 semanas</div>
                        </div>
                    </div>
                </div>
                <div className="column2-planes">
                    <div className="titulo-amarillo-planes">
                        CLASES VIRTUALES
                    </div>
                    <div className="parrafo-planes">
                        Piano, Guitarra, Canto, Bajo, Batería, Percusión e Instrumento de Cuerda Frotada
                        <div className="description-planes">
                            <p>1 Mes</p>
                            <p>Paquete x 4 clases</p>
                            <p className="precio">$220.000</p>
                            <div className="vigencia-planes">Vigencia de 6 semanas</div>
                        </div>
                        <div className="description-planes description1-color">
                            <p>2 Meses</p>
                            <p>Paquete x 8 clases</p>
                            <p className="precio">$380.000</p>
                            <p className="precio-mes">Precio mes $190.000</p>
                            <div className="vigencia-planes">Vigencia de 12 semanas</div>
                        </div>
                        <div className="description-planes description2-color">
                            <p>3 Meses</p>
                            <p>Paquete x 12 clases</p>
                            <p className="precio">$504.000</p>
                            <p className="precio-mes">Precio mes $168.000</p>
                            <div className="vigencia-planes">Vigencia de 6 semanas</div>
                        </div>
                    </div>
                </div>
                <div className="column3-planes">
                    <div className="titulo-amarillo-planes">
                        KIDS PRESENCIAL
                    </div>
                    <div className="parrafo-planes">
                        Iniciación Musical, Pre-Piano, Ballet, Gimnasia Rítmica
                        <div className="description-planes">
                            <p>1 Mes</p>
                            <p>Paquete x 4 clases</p>
                            <p className="precio">$250.000</p>
                            <div className="vigencia-planes">Vigencia de 6 semanas</div>
                        </div>
                        <div className="description-planes description1-color">
                            <p>2 Meses</p>
                            <p>Paquete x 8 clases</p>
                            <p className="precio">$440.000</p>
                            <p className="precio-mes">Precio mes $210.000</p>
                            <div className="vigencia-planes">Vigencia de 12 semanas</div>
                        </div>
                        <div className="description-planes description2-color">
                            <p>3 Meses</p>
                            <p>Paquete x 12 clases</p>
                            <p className="precio">$599.000</p>
                            <p className="precio-mes">Precio mes $199.700</p>
                            <div className="vigencia-planes">Vigencia de 6 semanas</div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Planes;