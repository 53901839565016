import React, {useEffect, useRef, useState } from 'react';
import './curso.css';
import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Imgenlogo from '../../images/logoMinotaBlanco.png';
import FondoAlumnos from '../../images/FondoAlumnos.png';

const profesor = "German";

const listaEstudiantes = [
    {codigo:"001", nombre:"María Angelica García Montes", curso:"Guitarra", profesor:"Martín Andrés Cortés Gómez"},
    {codigo:"002", nombre:"Carolina Gutiérrez Díaz", curso:"Violin", profesor:"María Camila Orozco Beltrán"},
    {codigo:"003", nombre:"Juan Carlos Vega Castañeda", curso:"Piano", profesor:"José Joaquín Mendoza Urdaneta"},
    {codigo:"004", nombre:"José Antonio Medina Silva", curso:"Saxofón", profesor:"Patricia Gómez Márquez"},
    {codigo:"005", nombre:"Miguel de Jesus Buendía Méndez", curso:"Bateria", profesor:"Victor Hugo Gómez Suárez"},
    {codigo:"006", nombre:"Mildred Andrea Soler Bautista", curso:"Flauta", profesor:"Carmen Patricia Sanchez Gutiérrez"},
    {codigo:"007", nombre:"Guillermo Andrade Mosquera", curso:"Trompeta", profesor:"Carlos Eduardo Marín Torres"},
    {codigo:"008", nombre:"Fabían Steven Marín Tovar", curso:"Piano", profesor:"Carlos Javier Holgin Alvarez"},
    {codigo:"010", nombre:"Sandra Milena Benitez Morán", curso:"Bateria", profesor:"Victor Hugo Gómez Suárez"},
    {codigo:"011", nombre:"Julían José Contreras Gómez", curso:"Piano", profesor:"José Joaquín Mendoza Urdaneta"},
    {codigo:"012", nombre:"Sandra Milena Benitez Morán", curso:"Saxofón", profesor:"Patricia Gómez Márquez"},
    {codigo:"013", nombre:"Guillermo Andrade Mosquera", curso:"Saxofón", profesor:"Patricia Gómez Márquez"},
    {codigo:"014", nombre:"Fabían Steven Marín Tovar", curso:"Piano", profesor:"José Alfredo Puertas Blanco"},
    {codigo:"015", nombre:"Sandra Cruz Paz", curso:"Violin", profesor:"Stella Benitez Nuñez"},
    {codigo:"016", nombre:"Fernando Cortes Marín", curso:"Saxofón", profesor:"Xiomara Castro Márquez"},
    {codigo:"017", nombre:"Claudia Patricia Correa Benitez", curso:"Trompeta", profesor:"Carlos Eduardo Marín Torres"},
    {codigo:"018", nombre:"Claudia Patricia Correa Benitez", curso:"Trompeta", profesor:"Carlos Eduardo Marín Torres"},
];

function Curso() {

    const [alumnoSeleccionado, setAlumnoSeleccionado] = useState({codigo:"000", nombre:"No seleccionado", curso:"", profesor:"    "});
    const [message, setMessage] =useState({'001':[{id:"11111111111111", mensaje:"Inicio de programación de Actividades", fecha:"14/9/2021"}]});
    const inputMessage=useRef(null);
    const emoticon=useRef(null);
    const scrollElement = useRef(null); 

    const filas = listaEstudiantes.map((estudiante, index)=> {
        return (
        <tr key={index}> 
            <td>
                <div className="input-group-text" onClick={()=> {setAlumnoSeleccionado(estudiante)}} id={estudiante.nombre} >
                    <span className="icon-nombre">
                        {estudiante.nombre}
                    </span>
                    <span>
                        {estudiante.curso}
                    </span>
                </div>
            </td>
        </tr>)});

    const handleInputChange = () => {
        const fecha = new Date();
        
        setMessage((prev) => {
            return {...prev,
                    [alumnoSeleccionado.codigo]:
                            [
                                ...(prev[alumnoSeleccionado.codigo] ? (prev[alumnoSeleccionado.codigo]):[]),
                                { id: (new Date()).getMilliseconds(),
                                    mensaje: inputMessage.current.value, 
                                    fecha: fecha.getDate()+"/"+(fecha.getMonth() +1)+"/"+fecha.getFullYear()
                                }
                            ]
            }
        }); 

        setTimeout(()=>{inputMessage.current.value="";},100);
    };
    
         
    const inputEnter = (e) => {
        if(e.key==="Enter" && e.target.value!=""){
            handleInputChange()
            console.log(scrollElement)
        }
    }

    const inputclic = () => {
        if( inputMessage.current.value!==""){
            handleInputChange()
            console.log(scrollElement)
        }
    }

    const welcome = () => {
        return(
            <div className="bienvenida" >
               <span> Hola profe... {profesor} </span>  
               <h2>¡Bienvenido</h2>  
               <span>a tu nueva plataforma de clases!</span> 
            </div>
    )};


    useEffect(()=>{scrollElement.current.scrollTop=scrollElement.current.scrollHeight},[message])

    return(
        <div id="cont-curso" className="contenedor-curso">   
            <div className="seccion-logo-minota">
                <div className="card">                   
                    <div className ="img-logo-curso" >
                        <img className="imagen-logo" src={Imgenlogo} alt="logo-academy"/>
                    </div>
                </div>
            </div>      
            <div>
                <div className="fila-inicial">
                    <div className="card-principal-curso"> 
                        <div className="row">
                            <div className="col-md-4"> 
                                <div className="card-fila-inicial"   id="card-prof">
                                    <span>Profesor: {profesor}</span>   
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="card-estud-seleccionados">
                                    <div className="col" >
                                    <div className="text-student">
                                            <span>Alumno</span>
                                        </div>
                                        <div className="text-separador">
                                            <span> * </span>
                                        </div>
                                        <div className="text-student">
                                            <span>{alumnoSeleccionado.nombre}</span>
                                        </div>
                                    
                                        <div className="text-separador">
                                            <span> * </span>
                                        </div>
                                    
                                        <div className="text-curso">
                                            <span>{alumnoSeleccionado.curso}</span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
            </div> 
            <div>
                <div className="card-principal-curso" > 
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card-principal-curso" id="listado">
                                <div className="tabla" >
                                    <div className="tabla-modificada"> 
                                        <h6>Listado de Estudiantes</h6>
                                        <div className="card-tabla">
                                            <table className="table-responsive">
                                                <tbody>
                                                    {filas}
                                                </tbody>
                                            </table>
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="gestion">
                                <div className="gestion-card">
                                    <img className="fondo-alumnos" src={FondoAlumnos} alt="fondo-centro"/>
                                    
                                    <div className="parte-msje">
                                        <div className="gestion-curso" ref={scrollElement}>
                                            <div className="parte-central">
                                                <div className="mensajes">
                                                    {alumnoSeleccionado?.nombre === "No seleccionado"? welcome():
                                                    message[alumnoSeleccionado.codigo]?.map(mensaje=>{ 
                                                        return(
                                                        <div key={mensaje.id}  className="sec-mensaje-unico">
                                                            <div className="style-fecha"> 
                                                                {mensaje.fecha}
                                                            </div> 
                                                            <div className="style-mensaje-container">
                                                                <div className="style-mensaje">
                                                                    {mensaje.mensaje}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={alumnoSeleccionado?.nombre === "No seleccionado"? "disabled-input secc-msje": "secc-msje"} >
                                        <div className="icons">
                                            <div className="secc-icons">
                                                <button className="icon1" ref={emoticon} name="emoticon"></button>
                                                <button className="icon2" ref={emoticon} name="enlace"></button>               
                                            </div>
                                        </div>
                                        <div className="input-text" >
                                            <input 
                                                ref={inputMessage}
                                                className="form-control" 
                                                type="text" 
                                                placeholder="Ingrese Actividad Programada" 
                                                name="mensaje" 
                                                onKeyUp={(e)=>{inputEnter(e)}}
                                                id="id-form-control"
                                            >
                                            </input>
                                        </div>
                                        <div className="input-btn">  
                                            <div className="boton">
                                                <button  onClick={()=>{inputclic()}} name="addmsje"></button>
                                            </div>
                                        </div>
                                    </div>  
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>        
            </div> 
            <div className="footer">
                <div className="card-footer">
                </div>
            </div>
            <div className="derechos">
                <h6>Copyright@ 2021 MiNota Music Academy. All rights reserved. The MiNota Music Academy name and the MiNota Music Academy logo are registered trademarks.</h6>
            </div>
    </div>
    );
    };
    
export default Curso;
