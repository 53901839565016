import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    style: {
        color: 'white',
        background: 'linear-gradient(#25d366,#25d366)13px 75%/25px 7px no-repeat,radial-gradient(#25d366 50%,transparent 0)', 
        filter: 'drop-shadow( 3px 3px 4px rgba(0, 0, 0, .7))',
        position: 'fixed',
        bottom: '2%',
        right: '3%',
        zIndex: '101'
    }
});
const WhatsApp = () => {
    const classes = useStyles();
    return  <div className={classes.style}>
                <WhatsAppIcon style={{ fontSize: 80 }} />
            </div>
}
 
export default WhatsApp;