import React from 'react';
import ImgenSinger from '../../images/singer1.png';
import ImagenSingerGirl from '../../images/singer-girl2.png';
import ImagenBaterist from '../../images/drumset-boy.png';
import ImagenGuitarrist from '../../images/guitarrist-boy.png';
import ImagenDancerGirl from '../../images/dancer-girl.png';
import Underline from '../../images/underline.svg';
import { Typography } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import './Header.css';
import LogoKids from '../../images/laVozKids.png';
import LogoCaracol from '../../images/caracolLogo.png';

const MainHeader = (props) => {
    const isUpSmallBreakPoint = isWidthUp('sm', props.width);
    const subtitleHeaderSize = isUpSmallBreakPoint ? 'h6': 'subtitle2';
    return ( 
        <header className="App-header">
        <img className="imagen-inicio" src={ImgenSinger} alt="cantante mi nota"/>
        <img className="imagen-second" src={ImagenBaterist} alt="baterista mi nota"/>
        <img className="imagen-third" src={ImagenSingerGirl} alt="solista mi nota"/>
        <img className="imagen-fourth" src={ImagenGuitarrist} alt="guitarrista mi nota"/>
        <img className="imagen-fiveth" src={ImagenDancerGirl} alt="bailarina mi nota"/>
        <div className="textWrapper">
          <div className="colOther">
          </div>
          <div className="colMain">
            <div>
              <h1 className="hero_h1">
                Conviértete en
                <br></br>
                el mejor artista
              </h1>
              <Typography variant={subtitleHeaderSize} className="yellow hero_h2">¡Clases online o presenciales que sí funcionan!</Typography>

              <div className="wordsWrapper">
                <h1 className="hero_marker">
                  <span className="presencial">presencial</span> $280.000 <span className="mes">/ mes </span>
                  <br></br>
                  <span className="virtual">virtual</span> $220.000 
                </h1>
                <div className="underline-wrapper">
                  <div className="underline__clip-mask">
                    <img alt="" src={Underline} className="underline"></img>
                  </div>
                </div>
              </div>
              {/* <div className="lineDivisor"></div> */}
              <div className="containerLogoKids">
                <h5 className="kidsLegend">Participantes de</h5>
                <img className="logoKids" src={LogoKids} alt="La Voz Kids Imagen"/>
                <img className="logoCracol" src={LogoCaracol} alt="Caracol Imagen "/>
              </div>
              </div>
          </div>
        </div>
        <div className="backGround"></div>
        <div className="gradient"></div>
      </header> 
     );
}
 
export default withWidth()(MainHeader);