import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';


const useStyles = makeStyles(theme => ({
    barSuperior: {
        display: 'flex',
        position: 'fixed',
        zIndex: '99',
        width: '100%',
        top: '3px',
        backgroundColor: 'white',
        padding: '0',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
            marginRight: '-10px',
            paddingRight: '17px',
            right: '10px'
        },
        color: theme.palette.text.primary,
        '& p': {
            color: theme.palette.text.primary
        },
    },
    barSuperior1: {
        paddingTop: '1rem'
    },
    containerPeople: {
        display: 'flex', 
    },
    telefone: {
        display: 'flex'
    }
}));
const SuperiorBar = () => {
    const classes = useStyles();
    return ( 
    <>
    <span className={classes.barSuperior}>
        <div className={classes.containerPeople}>
                <EmailIcon color="primary"></EmailIcon>
                <Typography  variant="caption">info@minotamusic.com</Typography>
        </div>
        <div className={classes.telefone}>
                <PhoneIcon color="primary"></PhoneIcon>
                <Typography  variant="caption">(+57) 324 3223477</Typography>
        </div>
    </span>
    </>
    );
}
 
export default SuperiorBar;