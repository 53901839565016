import React from 'react';
//import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core';
import $ from 'jquery';

const useStyles = makeStyles(theme => ({
    section : {
      [theme.breakpoints.up('sm')]: { 
        paddingTop: '100px',
    },
      paddingTop: '10px',
      fontFamily: 'Barlow Semi Condensed',
      margin: '0 16px 0 16px'
    },
    center: {
      position: 'relative',
      zIndex: '10',
      textAlign: 'center'
    },
    textUnderline: {
      display: '-webkit-flex',
      justifyContent: 'center'
    },
    subTitle: {
      position: 'relative',
      zIndex: '10',
      marginBottom: '10px',
      paddingRight: '10px',
      paddingLeft: '10px',
      backgroundColor: '#662383',
      color: '#fff',
      fontSize: '35px',
      lineHeight: '50px',
      fontWeight: '500',
    },
    subTitle1: {
      marginTop: '-10px',
      marginBottom: '10px',
      fontSize: '45px',
      lineHeight: '50px',
      fontWeight: '600',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: { 
        fontSize: '65px',
        lineHeight: '70px',
      },
    },
    miniContainer: {
      maxWidth: '650px',
      textAlign: 'center',
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    secondaryText: {
      [theme.breakpoints.up('sm')]: { 
        marginBottom: '50px',
      },
      marginBottom: '10px',
      fontSize: '19px',
      lineHeight: '30px',
      marginTop: '0'
    },
  
    carrusel: {
      minHeight: '500px',
      backgroundColor: 'transparent',
      boxShadow: 'none'
    },
    carrusel1: {
      height: '100%',
      whiteSpace: 'nowrap',
      textAlign: 'left'
    },
    carrusel2: {
      opacity: '1',
      zIndex: '696',
      transition: 'opacity 650ms ease-in-out 0s'
    },
    flexRow: {
      display: 'flex',
      minHeight: '100%',
      justifyContent: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: { 
        flexWrap: 'nowrap'
    },
      flexWrap: 'wrap'
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: { 
        paddingRight: '50px',
        paddingLeft: '50px',
      },
      paddingRight: '20px',
      paddingLeft: '20px',
      whiteSpace: 'normal'
    },
    flexImagen: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      marginRight: '50px',
      marginLeft: '50px',
      '& img': {
        width: '100%',
        minWidth: '350px'
      },
      '& iframe': {
        border: 'solid #662383 7px',
      }
    },
    tituloTestimonio: {
      '& h2': {
        marginTop: '10px',
        marginBottom: '10px',
        [theme.breakpoints.up('sm')]: { 
          fontSize: '45px',
          lineHeight: '55px',
        },
        fontSize: '35px',
        lineHeight: '40px',
      },
      
      fontWeight: '700',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: { 
        marginTop: '10px',
        marginBottom: '20px',
      }
    },
    parrafo: {
      marginBottom: '0px',
      fontSize: '15px',
      lineHeight: '24px'
    },
    author: {
      marginTop: '15px',
      marginBottom: '10px',
      fontSize: '20px',
      lineHeight: '25px',
      textTransform: 'uppercase'
    },
    testimonios: {
      zIndex: '54',
      position: 'relative',
      top: '-100px'
    }
}));

const Testimonios = (props) => {
  const classes = useStyles();
  const [width, setWidth] = React.useState(1000);
  const [showUpVideo, setShowUpVideo] = React.useState(false);
  const [arriba, setArriba] = React.useState(true);
  const [path, setPath] = React.useState(-150);
  React.useEffect(() => {
    if(window && window.outerWidth) {
      if(window.outerWidth<500) {
        setShowUpVideo(true);
      }
      else {
        setShowUpVideo(false);
      }
      setWidth(window.outerWidth-30);
    } 
  },[]);

  React.useEffect(() => {
    const onScroll = e => {
      const scrollTop = e.target.documentElement.scrollTop;
      if ($("#testimonios")[0].offsetTop -150<scrollTop) {
        setArriba(false);
      }else {
        setArriba(true);
      }
      if ($("#testimonios")[0].offsetTop -150<scrollTop && $("#testimonios")[0].offsetTop>scrollTop){
        setPath((scrollTop-($("#testimonios")[0].offsetTop -150)));
      }else{
        if ($("#testimonios")[0].offsetTop -150>scrollTop && $("#testimonios")[0].offsetTop -300<scrollTop){
          setPath(-1*(($("#testimonios")[0].offsetTop - 150)-scrollTop));
        }   
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const MyPureIframe = React.memo(({src, width, height}) => (
    <iframe title="Youtube Testimonios" src={src} width={width} height={height} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                   
  ), [true]);



// Descripción Salomé

// Salomé es un talento increíble!!! Ha llevado su proceso con nosotros desde el 2017. Ha participado en varias ediciones de nuestro "MiNota Live Concert", además de diferentes concursos y conciertos en importantes escenarios. Hemos tenido la oportunidad de acompañarla en sus grandes logros y de ayudarla en su desarrollo de manera presencial y virtual.


// Descripción Inka
  
// Conocimos a Inka cuando empezó su proceso en Iniciación Musical, más tarde pasó a Pre-Piano y actualmente hace parte de nuestro programa de Piano, que buscar explorar las aptitudes musicales y el aprendizaje de la música desde la didáctica y la pedagogía.

  const items = [
        {
            name: "Mi experienica ha sido maravillosa...",
            description: "Cristancho estudia en nuestra Academia desde 2019, tomando clases de forma presencial y actualmente de forma virtual. Parte de su proceso ha sido explorar la voz como un complemento importante en su carrera actoral. Estamos felices de ver sus avances y de poder acompañarlo en desarrollo de su talento como cantante, de la mano de nuestros instructores.",
            estudiante: "Cristancho",
            horientacion:"v",
            urlYoutube: "pd7kTV2pph0"
            
        },
        {
          name: "Aprender Piano fue fantástico ...",
          description: 'Hana empezó con nosotros a desde muy pequeña en clases de Canto, Piano y Gimnasia rítmica, logrando un complemento fabuloso que le permitió participar en la "La Voz Kids 2020". Hoy en día continúa su proceso de formación musical con nosotros, de forma virtual desde Estados Unidos.',
          estudiante: "Hana",
          horientacion:"v",
          urlYoutube: "dKxfukq_7zw"
        },
        {
          name: "Mi voz es mejor ahora, gracias a MiNota...",
          description: 'Salomé es un talento increíble!!! Ha llevado su proceso con nosotros desde el 2017. Ha participado en varias ediciones de nuestro "MiNota Live Concert", además de diferentes concursos y conciertos en importantes escenarios. Hemos tenido la oportunidad de acompañarla en sus grandes logros y de ayudarla en su desarrollo de manera presencial y virtual.',
          estudiante: "Salomé",
          horientacion:"v",
          urlYoutube: "TvKEMkFg5Yo"
        },
        {
          name: "MiNota es la mejor academia",
          description: "Conocimos a Inka cuando empezó su proceso en Iniciación Musical, más tarde pasó a Pre-Piano y actualmente hace parte de nuestro programa de Piano, que buscar explorar las aptitudes musicales y el aprendizaje de la música desde la didáctica y la pedagogía.",
          estudiante: "Inka hija Carolina Cuervo",
          horientacion:"h",
          urlYoutube: "-80DCP4a3Tk"
        },
        {
          name: "El cambio en mi voz fue muy notorio, gracias a MiNota",
          description: "Paula Salguero sentia su voz estaba estancada, ahora su voz ha cambiado y es otra cantante.",
          estudiante: "Paula Salguero",
          horientacion:"v",
          urlYoutube: "Eohot34uvMY"
        },
    ];
   const Item = (props) =>
      {
          return (
              <Paper className={classes.carrusel}>
                <div className={classes.carrusel1}>
                  <div className={classes.carrusel2}>
                    <div className={classes.flexRow}>
                       {showUpVideo && <div className={classes.flexImagen}>
                                  <MyPureIframe src={"https://www.youtube-nocookie.com/embed/"+props.item.urlYoutube+"?controls=0"} height={width<600 ? 250 : (width/2) * 0.56} width={width<600 ? (width-46) : (width/2)}></MyPureIframe>              
                        </div> }
                      <div className={classes.flexColumn}>
                        <div className={classes.tituloTestimonio}>
                          <h2>{props.item.name}</h2>
                        </div>
                        <p className={classes.parrafo}>{props.item.description}</p>
            
                        <div className={classes.author}>
                            {props.item.estudiante}
                        </div>
                      </div>
                     {!showUpVideo && <div className={classes.flexImagen}>
                                <MyPureIframe src={"https://www.youtube-nocookie.com/embed/"+props.item.urlYoutube+"?controls=0"} height={width<600 ? 250 : (width/2) * 0.56} width={width<600 ? (width-46) : (width/2)}></MyPureIframe>              
                      </div> }
                    </div>
                  </div>
                </div>
              </Paper>
          )
      };
   // const isUpSmallBreakPoint = isWidthUp('sm', props.width);
    return (
      <> 
      <section id="testimonios" className={classes.testimonios}>
        <div className={classes.transition}>
          <svg id="scroll" height="200" width={width}>
              <path d={`M 0 100 q ${width/2} ${path} ${width} 0 Z`} stroke={arriba ? "white" : "#1a1719"} strokeWidth="5" fill={arriba ? "white" : "#1a1719"} />
          </svg>
        </div>
        <div className={classes.section}>
          <div className={classes.center}>  
            <div className={classes.textUnderline}>
              <div className={classes.subTitle}>
                  RESULTADOS REALES
              </div>
            </div>
            <div className={classes.subTitle1}> Tan rápido como 3 meses</div>
            <div className={classes.miniContainer}>
              <p className={classes.secondaryText}>
                  Miles de nuevos artistas confían en nosotros, alrededor del mundo.
              </p>
            </div>
            <div className={classes.slider}>
            <Carousel autoPlay={true} indicators={true} animation="fade">
              {
                  items.map( (item, i) => <Item key={i} item={item} /> )
              }
            </Carousel>
            </div>  
          </div>
        </div>
        </section>
        <section id="testimonios1">

        </section>
        </>
     );
};
 
export default Testimonios;