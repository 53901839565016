import React from 'react';
import logo from '../../../src/minotavirtualsinbc.png';
import './PageNew.css';

function pageNew(props) {
  return (
    <div className="App-fix">
      <header className="App-header1">
        <img src={logo} className="App-logo" alt="logo" />
        {props.tipo === "pago" &&
        <>
        <div className="contenedorPagos">Con este boton podras pagar tus mensualidades, materiales, instrumentos, nuevos cursos y demas. Pronto llegara.</div>
        <div id="testbutton">
        </div>
        </>    
        }
        <p>
          {`Estamos trabajando para traerte el lugar de  ${props.tipo}, y darte el mejor servicio.`}
        </p>
        <a
          className="App-link"
          href="/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Volver a home
        </a>
      </header>
    </div>
  );
}
export default pageNew;