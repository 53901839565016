import { formatMs } from '@material-ui/core';
import ReactPixel from 'react-facebook-pixel';
import React, { useEffect, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import * as tweenFunctions  from 'tween-functions';
import './Felicitaciones.css';
import telefono from '../../../images/telefono.png';
import celebracion from '../../../images/celebracion.png';
import carita from '../../../images/carita.png';
import pintura from '../../../images/pintura.png';
import piano from '../../../images/piano.png';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import wspfoto from '../../../images/wspfoto.png';
import backGround from '../../../images/Fondo_Felicitaciones.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    style: {
        color: 'white',
        backgroundColor: '#25d366', //'linear-gradient(#25d366,#25d366)13px 75%/25px 7px no-repeat,radial-gradient(#25d366 50%,transparent 0)', 
        filter: 'drop-shadow( 3px 3px 4px rgba(0, 0, 0, .7))',
        borderRadius: '10px',
        zIndex: '101'
    }
});
function Felicitaciones() {
    const classes = useStyles();
    const [runConfeti, setRunconfeti] = useState(true);
    const { width, height } = useWindowSize();
    
    useEffect(()=>{
        const advancedMatching = { em: 'mancho@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
        };
        ReactPixel.init('1809875732521936', advancedMatching, options);

        ReactPixel.pageView(); 
        ReactPixel.trackSingle('1809875732521936', "CompleteRegistration", { content_name: 'MN-LP1-FIRST-PAGE'});
        setTimeout(()=>{
            setRunconfeti(false);
        },5000);
    },[]);
    return(
        <div className="container-responsive-lp1-felicitaciones"> 
        <div className="container-responsive-auxiliar-lp1-felicitaciones">
            <div className="container-felicitaciones">
                {runConfeti && <Confetti
                    width={width}
                    height={height}
                />}
                <div className="container-image">
                <h1 className="Titulo"> ¡FELICIDADES!</h1>
                <h2 className="Titulo1"> ya tienes tu oferta</h2>
                <h3 className="Text1">
                    Pero espera... En Minota estamos comprometidos con el desarollo
                    artístico de tus hijos y queremos ir más allá.
                </h3>
                <div className="container-blanco"> 
                    <div className="fila centro">
                        <p className="texto1"> Si eres una de las primeras <span className="negrilla"> 5 personas que
                            nos escribe al Whatsapp </span>  
                                <img src={telefono} className="telefono"></img>, podrás recibir adicional:
                        </p>
                    </div>
                    <div className="fila">
                        <div>   <img src={celebracion} className="sticker"></img></div>
                        <div className="vertical-center"> 
                            <p className="texto1"> <span className="negrilla">  Una clase de refuerzo </span> en el primer mes. </p>
                        </div>
                    </div>                                                                                                                      
                    <div className="fila">
                        <div> <img src={carita} className="sticker"></img> </div>
                        <div className="vertical-center"> 
                            <p className="texto1"> <span className="negrilla"> 30% de descuento </span>en el segundo mes. </p>
                        </div>
                    </div>
                    <div className="fila">
                        <div> <img src={pintura} className="sticker"></img> </div>
                        <div className="vertical-center"> 
                            <p className="texto1"> <span className="negrilla"> Continuar con la clase grupal </span>
                            de su preferencia, sin costo adicional. </p>
                        </div>
                    </div>
                    <div className="fila">
                        <div> <img src={piano} className="sticker"></img></div>
                        <div className="vertical-center"> 
                            <p className="texto1"> <span className="negrilla">15% de descuento </span> en la compra del instrumento favorito, 
                                para tomar las clases. </p>
                        </div>
                    </div>
                </div>
                <div className="datos-contacto">
                    <div className="textoWsp">
                        <div className="textoCentrado"> 
                            <p> <span className="textoWsp1">No pierdas la oportunidad de llevar
                            este bono adicional</span>, 
                            solo si eres de las primeras 5 personas que nos escribe por Whatsapp. </p>
                        </div>
                        <button className={classes.style}  onClick={()=> window.open("https://wa.link/d6ys2f", "_blank")}>
                            Click Aqui para mandar mensaje
                            <WhatsAppIcon style={{ fontSize: 60 }} />
                        </button>
                    </div>
                    <div className="direccion">
                        <p className="direccion1"> {"Te esperamos en la "}<span className="negrilla"> {" * "}Cra. 11 # 93b-31 - Local 6</span> </p>
                        <div className="mapa">   
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.535214751293!2d-74.04894048600374!3d4.676613143169985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a91e57bae6d%3A0x19e8e95e3053af6a!2sMinota%20music%20academy!5e0!3m2!1ses!2sco!4v1629688146826!5m2!1ses!2sco" width="70%" height="120" allowFullScreen="" loading="lazy"></iframe>
                        </div>
                        <div className="direccion1">Derechos Reservados</div>
                    </div>
                </div>
                </div>    
            </div>
        </div>
        </div>       
    );  
}
export default Felicitaciones;