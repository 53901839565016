import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import YoutubeVideo from './YoutubeVideo';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import Colors from '../utils/colors';
import Underline from '../../images/underline2.svg';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const useStyles = makeStyles((theme) => ({
        introSection: {
            position: 'relative',
            zIndex: '20',
            paddingRight: '20px',
            paddingBottom: '100px',
            [theme.breakpoints.up('sm')]: { 
                paddingBottom: '150px',
            },
            paddingLeft: '20px',
            backgroundImage: 'linear-gradient(0deg, #191619 4%, #191619 62%, transparent)',
            perspective: '1000px',
            color: '#fff',
            textAlign: 'center'
        },
        videoContainer: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '940px',
            position: 'relative',
            zIndex: '10',
            marginTop: '-50px',
            marginBottom: '25px',
            [theme.breakpoints.up('sm')]: { 
                marginBottom: '50px',
            }
        },
        opacoFiltro: {
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: 'rgba(0,0,0,0.4)',
            height: '100%',
            width: '100%'
        },
        volume: {
            position: 'absolute',
            right: '0',
            top: '0',
            padding: '20px',
            animation: `$heartbeat 2000ms infinite`,
        },
        '@keyframes heartbeat':{
            '0%':
            {
                transform: 'scale( .75 )'
            },
            '20%':
            {
                transform: 'scale( 1 )'
            },
            '40%' :
            {
                transform: 'scale( .75 )'
            },
            '60%' :
            {
                transform: 'scale( 1 )'
            },
            '80%' :
            {
                transform: 'scale( .75 )'
            },
            '100%' :
            {
                transform: 'scale( .75 )'
            }
        },
        play: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            [theme.breakpoints.up('sm')]: { 
                padding: '10px 20px',
            },
            padding: '0px 8px',
            backgroundColor: `${Colors.purple}aa`
        },
        show: {
            display: 'block',
            zIndex: '999'
        },
        mainVideo: {
            transform: 'translate(0, -100%)',
            opacity: '0'
        },
        mainVideoShow: {
            transform: 'translate(0, -100%)',
            opacity: '1'
        },
        previewVideo: {
            zIndex: '-2'
        },
        textoVideo: {
            position: 'absolute',
            top: '75%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            padding: '10px 20px',
            color: 'white',
            fontFamily: 'Barlow Semi Condensed',
            width: '100%',
            fontWeight: '1000',
            fontSize: '3vw'
        },
        artistInside: {
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'Oswald, sans-serif',
            fontWeight: '500',
            textTransform: 'uppercase',
            '& .smallerText': {
                backgroundColor: Colors.purple,
                fontFamily: 'Barlow Semi Condensed',
                display: '-webkit-box',
                fontSize: '14px',
                lineHeight: '50px',
                [theme.breakpoints.up('sm')]: { 
                fontSize: '35px',
                }
            },
            '& .biggertext': {
                [theme.breakpoints.up('sm')]: { 
                    fontSize: '65px',
                    marginTop: '20px',
                },
                fontSize: '20px',
                fontFamily: 'Barlow Semi Condensed',
                marginTop: '6px',
                backgroundColor: 'transparent',
                '& .purple': {
                    color: Colors.yellow
                }
            }        
        },
        underlineContainer: {

        },
        underline: {
            width: '25%',
            float: 'right',
            marginRight: '14%',
            marginBottom: '20px',
            filter: 'grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg) saturate(500%) contrast(0.7)'
        }
    })
);
const FisrtSection = (props) => {
    const isUpSmallBreakPoint = isWidthUp('sm', props.width);
    const fontSizePlay = isUpSmallBreakPoint ? 80 : 50;
    const classes = useStyles();
    const [showVideo,setShowVideo] = React.useState(false);
    const divRef = React.useRef(null);
    const idVideoPreview = 'AVpaC4ehIx8';
    const playerPropertyPreview= {color: "white", autoplay: '1',mute: '1', controls: '0', rel: '0', branding: '0', showinfo: '0', loop: '1', playlist: idVideoPreview, start:'60'};
    const playerProperty= {color: "white", autoplay: '0',mute: '0', controls: '1', rel: '0', branding: '0', showinfo: '0'};
    const idCallBack = "mainVideo1";
    const handlePlayClick = (event, id) => {
        if(event===1 && id === idCallBack) {
            setShowVideo(true);
        }
    };

    React.useEffect(() => {
        //Resize element
        const videoHeight = divRef.current.offsetHeight;
        document.getElementById("videoContainer").style.height = videoHeight + "px";
     
    },[divRef]);

    return ( 
        <section className={classes.introSection}>
            <div id="videoContainer" className={classes.videoContainer}>
                <div className={classes.opacoFiltro}>
                    <div className={classes.play}>                     
                        <PlayArrowIcon style={{ fontSize: fontSizePlay }} ></PlayArrowIcon>
                    </div>
                    <div className={classes.volume}>
                        <VolumeOffIcon style={{ fontSize: 30 }}></VolumeOffIcon>
                    </div>
                    <div className={classes.textoVideo}>
                        <h1>
                            Mira Cómo Funciona!
                        </h1>
                    </div>
                </div>
                <div ref={divRef} className={classes.previewVideo}> 
                    <YoutubeVideo id={idVideoPreview} idCallBack="previewVideo1" playerProperties={playerPropertyPreview} handlePlay={handlePlayClick}></YoutubeVideo>
                </div> 
                <div className={showVideo ? classes.mainVideoShow : classes.mainVideo}>  
                    <div className={classes.show}>
                        <YoutubeVideo showOver={true} idCallBack={idCallBack}  id={idVideoPreview} playerProperties={playerProperty} handlePlay={handlePlayClick}></YoutubeVideo>
                    </div>
                </div> 
            </div>  
            <div className="containerText">
                <div className={classes.artistInside}>
                    <div className="smallerText">
                        DENTRO DE CADA UNO HAY UN INCREÍBLE ARTISTA
                    </div>
                </div>
                <div className={classes.artistInside}>
                    <div className="biggertext">
                        ¡DÉJANOS AYUDARTE A <span className="purple">ENCONTRARLO!</span>
                    </div>
                </div>
                <div className={classes.underlineContainer}>
                    <img className={classes.underline} src={Underline} alt=""></img>
                </div>
            </div>   
        </section>
        );
}
 
export default withWidth()(FisrtSection);