import React from 'react';
import './login.css';
import { Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import imagenlogin from '../../images/logoMinotaBlanco.png';
import FondoLogin from '../../images/FondoLogin.png';

function Login() { 
       
    const leterusername = 'User'; 
    const leterpassword = 'Password';   
    
    return( 
        <div className="container-login">
            <img className="img-fondo-login" src={FondoLogin}/>  
            <div className ="contenedor-login">
                <div className="container-secciones">
                    <div className="seccion1">
                        <div className ="img-logo" >
                        <img className="imagen-login" src={imagenlogin} alt="logo-academy"/>
                        </div>
                    </div>
                    <div className="seccion2">
                        <div className="container-user">
                            <h6>{leterusername}</h6>
                            <div className="entrada1" >
                                <div className="form-group"> 
                                    <div className="row">
                                        <div className=".col align-self-center">
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group input-group-md">
                                                    <span className="input-group-text" id="addon-wrapping">
                                                        <i className="fa fa-user bigicon"></i>
                                                        <Icon name='user'/>
                                                    </span>
                                                    <input type="email" 
                                                        className="form-control" 
                                                        name="correo" 
                                                        placeholder="e-mail" 
                                                        id="Correo" 
                                                        aria-describedby="sizing-addon1" 
                                                        required/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="seccion3">
                        <div className="container-password">
                            <h6>{leterpassword}</h6>
                            <div className ="entrada2">
                                <div classNAme="form-group1">
                                    <div class="row ">
                                        <div classNAme=".col align-self-center">
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group input-group-md">
                                                    <span className="input-group-text" id="addon-wrapping">
                                                        <i class="fa fa-user bigicon"></i>
                                                        <Icon name='lock'/>
                                                    </span>
                                                    <input type="password" 
                                                        name="contra" 
                                                        className="form-control" 
                                                        placeholder="******" 
                                                        aria-describedby="sizing-addon1" 
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="seccion4">
                        <div id="btn" >
                            <button className="btn btn-primary btn-block"  type="submit" value="Submit"><h6>Iniciar Sesión</h6></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    };
export default Login;
