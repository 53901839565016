import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import GuitarraInstru from '../../images/imagenesInstrumentos/BOTON WEB_Guitarra.png';
import GuitarraInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Guitarra RO.png';
import CantoInstru from '../../images/imagenesInstrumentos/BOTON WEB_Canto.png';
import CantoInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Canto RO.png';
import BateriaInstru from '../../images/imagenesInstrumentos/BOTON WEB_Bateria.png';
import BateriaInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Bateria RO.png';
import UkeleleInstru from '../../images/imagenesInstrumentos/BOTON WEB_Ukelele.png';
import UkeleleInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Ukelele RO.png';
import PianoInstru from '../../images/imagenesInstrumentos/BOTON WEB_Piano.png';
import PianoInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Piano RO.png';
import PercusionInstru from '../../images/imagenesInstrumentos/BOTON WEB_Percusion.png';
import PercusionInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Percusion RO.png';
import BajoInstru from '../../images/imagenesInstrumentos/BOTON WEB_Bajo Electrico.png';
import BajoInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Bajo Electrico RO.png';
import CuerdasInstru from '../../images/imagenesInstrumentos/BOTON WEB_Inst Cuerdas Frotadas.png';
import CuerdasInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Inst Cuerdas Frotadas RO.png';
import VientosInstru from '../../images/imagenesInstrumentos/BOTON WEB_Instr de viento.png';
import VientosInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Instr de viento RO.png';
import GimnasiaInstru from '../../images/imagenesInstrumentos/BOTON WEB_Gimnasia.png';
import GimnasiaInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Gimnasia RO.png';
import ArteInstru from '../../images/imagenesInstrumentos/BOTON WEB_Arte.png';
import ArteInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Arte RO.png';
import DanzaInstru from '../../images/imagenesInstrumentos/BOTON WEB_Danza.png';
import DanzaInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Danza RO.png';
import BalletInstru from '../../images/imagenesInstrumentos/BOTON WEB_Ballet.png';
import BalletInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Ballet RO.png';
import IniciazionInstru from '../../images/imagenesInstrumentos/BOTON WEB_Iniciacion.png';
import IniciazionInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Iniciacion RO.png';
import ProduccionInstru from '../../images/imagenesInstrumentos/BOTON WEB_Produccion Musical.png';
import ProduccionInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Produccion Musical RO.png';
import EstimulacionInstru from '../../images/imagenesInstrumentos/BOTON WEB_Estimulacion.png';
import EstimulacionInstruHover from '../../images/imagenesInstrumentos/BOTON WEB_Estimulacion RO.png';

import MinotaInstru from '../../images/logoMinotaNegro.png';


const useStyles = makeStyles((theme) => ({
        mainTitle: {
            margin: '10px',
            marginBottom: '40px'
        },    
        textUnderline: {
            display: '-webkit-flex',
            justifyContent: 'center',
            fontFamily: 'Barlow Semi Condensed',
            margin: '5px 16px 5px 16px'
        },
        subTitle: {
            position: 'relative',
            zIndex: '10',
            marginBottom: '10px',
            paddingRight: '10px',
            paddingLeft: '10px',
            backgroundColor: '#662383',
            color: '#fff',
            fontSize: '25px',
            lineHeight: '38px',
            fontWeight: '600',
            [theme.breakpoints.up('sm')]: { 
                fontSize: '30px',
                lineHeight: '70px',
            },
        },
        subTitle1: {
            marginTop: '-10px',
            fontFamily: 'Barlow Semi Condensed',
            marginBottom: '10px',
            fontSize: '35px',
            lineHeight: '38px',
            fontWeight: '600',
            textTransform: 'uppercase',
            [theme.breakpoints.up('sm')]: { 
            fontSize: '55px',
            lineHeight: '70px',
            },
        },    
        general: {
           
            [theme.breakpoints.up('sm')]: { 
                paddingBottom: '',
            },
            
            '& > div' : {
                width: '50%',
                display: 'inline-block',
                height: '32.5vw',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                marginTop: '-6px',
                [theme.breakpoints.up('sm')]: { 
                    width: '25%',
                    display: 'inline-block',
                    height: '16.5vw',
                },
                  
            },
            '& .guitarraInstru': {
                backgroundImage: 'url("'+GuitarraInstru+'")', 
            },
            '& .guitarraInstru:hover': {
                backgroundImage: 'url("'+GuitarraInstruHover+'")', 
            },
            '& .cantoInstru': {
                backgroundImage: 'url("'+CantoInstru+'")', 
            },
            '& .cantoInstru:hover': {
                backgroundImage: 'url("'+CantoInstruHover+'")', 
            },
            '& .bateriaInstru': {
                backgroundImage: 'url("'+BateriaInstru+'")', 
            },
            '& .bateriaInstru:hover': {
                backgroundImage: 'url("'+BateriaInstruHover+'")', 
            },
            '& .ukeleleInstru': {
                backgroundImage: 'url("'+UkeleleInstru+'")', 
            },
            '& .ukeleleInstru:hover': {
                backgroundImage: 'url("'+UkeleleInstruHover+'")', 
            },
            '& .pianoInstru': {
                backgroundImage: 'url("'+PianoInstru+'")', 
            },
            '& .pianoInstru:hover': {
                backgroundImage: 'url("'+PianoInstruHover+'")', 
            },
            '& .percusionInstru': {
                backgroundImage: 'url("'+PercusionInstru+'")', 
            },
            '& .percusionInstru:hover': {
                backgroundImage: 'url("'+PercusionInstruHover+'")', 
            },
            '& .bajoInstru': {
                backgroundImage: 'url("'+BajoInstru+'")', 
            },
            '& .bajoInstru:hover': {
                backgroundImage: 'url("'+BajoInstruHover+'")', 
            },
            '& .cuerdasInstru': {
                backgroundImage: 'url("'+CuerdasInstru+'")', 
            },
            '& .cuerdasInstru:hover': {
                backgroundImage: 'url("'+CuerdasInstruHover+'")', 
            },
            '& .vientoInstru': {
                backgroundImage: 'url("'+VientosInstru+'")', 
            },
            '& .vientoInstru:hover': {
                backgroundImage: 'url("'+VientosInstruHover+'")', 
            },
            '& .gimnasiaInstru': {
                backgroundImage: 'url("'+GimnasiaInstru+'")', 
            },
            '& .gimnasiaInstru:hover': {
                backgroundImage: 'url("'+GimnasiaInstruHover+'")', 
            },
            '& .arteInstru': {
                backgroundImage: 'url("'+ArteInstru+'")', 
            },
            '& .arteInstru:hover': {
                backgroundImage: 'url("'+ArteInstruHover+'")', 
            },
            '& .danzaInstru': {
                backgroundImage: 'url("'+DanzaInstru+'")', 
            },
            '& .danzaInstru:hover': {
                backgroundImage: 'url("'+DanzaInstruHover+'")', 
            },
            '& .balletInstru': {
                backgroundImage: 'url("'+BalletInstru+'")', 
            },
            '& .balletInstru:hover': {
                backgroundImage: 'url("'+BalletInstruHover+'")', 
            },
            '& .iniciazionInstru': {
                backgroundImage: 'url("'+IniciazionInstru+'")', 
            },
            '& .iniciazionInstru:hover': {
                backgroundImage: 'url("'+IniciazionInstruHover+'")', 
            },
            '& .produccionInstru': {
                backgroundImage: 'url("'+ProduccionInstru+'")', 
            },
            '& .produccionInstru:hover': {
                backgroundImage: 'url("'+ProduccionInstruHover+'")', 
            },
            '& .minotaInstru': {
                backgroundImage: 'url("'+MinotaInstru+'")', 
            },
            '& .estimulacionInstru': {
                backgroundImage: 'url("'+EstimulacionInstru+'")', 
            },
            '& .estimulacionInstru:hover': {
                backgroundImage: 'url("'+EstimulacionInstruHover+'")', 
            }
        },
       
       
    })
);
const Instrumentos = (props) => {
    const classes = useStyles();

    return ( 
        <div id="cursos">
            <div className={classes.mainTitle}>
                <div className={classes.textUnderline}>
                    <div className={classes.subTitle}>
                        ¡DESCUBRE TODOS NUESTROS CURSOS!
                    </div>
                </div>
                <div className={classes.subTitle1}> Empieza a cumplir tu sueño musical con nosotros</div>
            </div>
            <section className={classes.general}>
                <div className="guitarraInstru"></div>
                <div className="cantoInstru"></div>
                <div className="bateriaInstru"></div>
                <div className="ukeleleInstru"></div>
                <div className="pianoInstru"></div>
                <div className="percusionInstru"></div>
                <div className="bajoInstru"></div>
                <div className="cuerdasInstru"></div>
                <div className="vientoInstru"></div>
                <div className="gimnasiaInstru"></div>
                <div className="arteInstru"></div>
                <div className="danzaInstru"></div>
                <div className="balletInstru"></div>
                <div className="iniciazionInstru"></div>
                <div className="produccionInstru"></div>
                <div className="estimulacionInstru"></div>
            
            </section>
        </div>
        );
}
 
export default withWidth()(Instrumentos);