import React from 'react';
import Logo from '../images/logoMinotaBlanco.png';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Redes from '../components/RedesSociales';
const useStyles = makeStyles((theme) => ({
    containerBanner: {
        position: 'fixed',
        top:'24px',
        textAlign: 'left',
        zIndex: '100',
        width: '40%',  
        [theme.breakpoints.up('sm')]: { 
            width: '15%',
            top:'0px',
        }   
    },
    logo:{
        width: '100%',
        //filter: 'drop-shadow( 3px 3px 4px rgba(0, 0, 0, .7))'
    },
    containerImage: {
        width: '90%',
        backgroundColor: '#662383',
        padding: '16px',
        boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.15)',
        marginLeft: '10%'
    },
    line: {
        zIndex: '100',
        width: '100%',
        backgroundColor: '#662383',
        height: '3px',
        position: 'fixed',
        top: '0'
    }
}));
const BannerPrincipal = (props) => {
    const classes = useStyles();
    const isUpSmallBreakPoint = isWidthUp('sm', props.width);
    //Resize logo when scroll
    
    const mainLogo = document.getElementById("mainLogo");
    if(mainLogo) {
        if(!isUpSmallBreakPoint) {
            mainLogo.style.width = '40%';
        } else {
            if (props.onScroll) {
                mainLogo.style.width = '12%';
            } else {
                mainLogo.style.width = '15%';
            }
        }
    }
   
    

    return (
    <>
        <div className={classes.line}>
        </div>
        <div id="mainLogo" className={classes.containerBanner}>
            <div className={classes.containerImage}>
                <img className={classes.logo} src={Logo} alt="imageClass"/>
            </div>
            <Redes />
        </div>                                                                                                                                                                                                                                                                                                                          
    </> 
     );
}
 
export default withWidth()(BannerPrincipal);