import React , {useEffect} from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
 
const Example = (props) => {
 const createNotification = (type) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message');
          break;
        case 'success':
          NotificationManager.success('Success message', 'Title here');
          break;
        case 'warning':
          NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            alert('callback');
          });
          break;
      }
    };
  };
  useEffect(()=>{
    const data = props.notification();
    switch (data.tipo) {
        case 'info':
          NotificationManager.info(data.mensaje, data.title, data.delay);
          break;
        case 'success':
          NotificationManager.success(data.mensaje, data.title, data.delay);
          break;
        case 'warning':
          NotificationManager.warning(data.mensaje, data.title, data.delay);
          break;
        case 'error':
          NotificationManager.error(data.mensaje, data.title, data.delay);
          break;
    }
   
  },[]);
  
    return (
      <div>
          <NotificationContainer/>
      </div>
    );
}
 
export default Example;
