import React from 'react';
import CurveImage from '../../images/curveImage.svg';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
        imageSection: {
            position: 'relative',
            left: '-5%',
            top: '-1px',
            right: '-5%',
            bottom: 'auto',
            zIndex: '20',
            width: '110%',
            maxWidth: '110%'
        },
        section: {
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            display: 'none'
        }
    }
));
const SecondSection = () => {
    const classes = useStyles();
    return ( 
        <section className={classes.section}>
            <img  className={classes.imageSection} src={CurveImage} alt="imageCurve"></img>
        </section>
     );
}
 
export default SecondSection;