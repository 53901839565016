import React , {useEffect, useState}from 'react';
import CountDown from './components/CountDown';
import ReactPixel from 'react-facebook-pixel';
import SimpleModal from './components/ModalInscripcion';
import './LandingContainer1.css';
import Nino from '../../images/landingPage1/nino.png';
import Nino2 from '../../images/drumset-boy.png'
import Example from './components/Notifications';
import Fondo2 from '../../images/landingPage1/Landing Page_Beneficios CELULAR.jpg';
import LogoMinota from '../../images/logoMinotaBlanco.png';
import SingerGirl from '../../images/singer-girl2.png';
import Testimonios from '../../images/Testimonio1.png';
import Testimoniodos from '../../images/Testimonio2.png';
import Testimoniotres from '../../images/Testimonio3.png';

const LandingContainer1 = () => {
    let nombres1;
    const [shownotification, setShowNotification] = useState(false);
    const [nametoShow, setNametoShow] = useState('');
    const [shownotification1, setShowNotification1] = useState(false);
    const [nametoShow1, setNametoShow1] = useState('');
    const [promoActiva, setPromoActiva] = useState(false);
    const [openModalInscripcion, setOpenModalInscripcion] = useState(false);
    useEffect(()=>{
        
        const advancedMatching = { em: 'mancho@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
        };
        ReactPixel.init('1809875732521936', advancedMatching, options);

        ReactPixel.pageView(); 
        ReactPixel.trackSingle('1809875732521936', "ViewContent", { content_name: 'MN-LP1-FIRST-PAGE'});
        fetch("https://randomuser.me/api/?results=100&nat=es")
                .then(response => response.json())
                .then( (data) => {
                        let names = [];
                        data.results.forEach(element => {
                            names.push(element.name.first + " " + element.name.last);
                        });
                        nombres1 = names;
                        startAleatory();
                        startAleatory1();
                    }
                );
        return () => {
            setShowNotification(false);
            setNametoShow('');
            setShowNotification1(false);
            setNametoShow1('');
            setPromoActiva(false);
            setOpenModalInscripcion(false);
            nombres1=[];
        };
    },[]);
    let startTimeOut;
    let stopTimeOut;
    const startAleatory = () => {
        var myNumeroAleatorio10 = Math.floor(Math.random()*19);
        clearTimeout(stopTimeOut);
        startTimeOut = setTimeout(()=>startUp(), myNumeroAleatorio10*1000);
    };
    
    const startUp = () => {
        var myNumeroAleatorio100 = Math.floor(Math.random()*100);
        setNametoShow(nombres1[myNumeroAleatorio100] );
        setShowNotification(true);
        clearTimeout(startTimeOut);
        stopTimeOut = setTimeout(()=>{
            startAleatory();
            setShowNotification(false);
        }, 5000);
        
    };
    let startTimeOut1;
    let stopTimeOut1;
    const startAleatory1 = () => {
        var myNumeroAleatorio10 = Math.floor(Math.random()*17);
        clearTimeout(stopTimeOut1);
        startTimeOut1 = setTimeout(()=>startUp1(), myNumeroAleatorio10*1000);
    };
    
    
    const startUp1 = () => {
        var myNumeroAleatorio100 = Math.floor(Math.random()*100);
        setNametoShow1(nombres1[myNumeroAleatorio100] );
        setShowNotification1(true);
        clearTimeout(startTimeOut1);
        stopTimeOut1 = setTimeout(()=>{
            startAleatory1();
            setShowNotification1(false);
        }, 5000);
        
    };

    const callBack = (mensaje, title, delay, tipo) => {
        return {mensaje, title, delay, tipo}
    };

    const aleatory100 = () => {
        return Math.floor(Math.random()*100);
    };
    const promoChangeActive = (factor) => {
        setPromoActiva(factor);
    };

    const onHandleModal = () => {
        ReactPixel.trackSingle('1809875732521936', 'StartTrial', {content_name:"'MN-LP1-FIRST-PAGE'"});
        setOpenModalInscripcion(true);
    };

    return( 
    
    <div className="container-responsive-lp1"> 
        <div className="container-responsive-auxiliar-lp1">    
            <div className="landing-container">
                    <CountDown promoActive={promoChangeActive}/>
                    <h1 className="titulo-principal"> APRENDE A TOCAR UN INSTRUMENTO DESDE CASA</h1>
                    <p className="text-principal">
                        Cada vez más padres están interesados en cultivar en sus hijos el amor al arte y la musica y desarrollar sus habilidades.
                        <span> Nos comprometemos a que tus hijos descubran sus talentos y a forjar el artista que hay en ellos.</span>
                    </p>
                    <div className="section1">
                        <div className="column">
                            <p className="bigText marginTop">
                                <span>Valor de mensualidad:</span>
                            </p>
                            <p className="center">
                                <span>⇩</span>
                            </p>
                            <p className="precioAntes">
                                VIRTUAL: <span>$220.000</span>
                            </p>
                            <p className="precioAntes">
                                PRESENCIAL: <span>$280.000</span>
                            </p>
                            <p className="descuento">
                                30% DTO
                            </p>
                            <p className="center">
                                <span>⇩</span>
                            </p>
                            <p className="precioPromo">
                                VIRTUAL: $220.000
                                PRESENCIAL: $280.000
                            </p>
                        </div>
                        <div className="column1">
                            <img src={Nino} />
                        </div>
                    </div>
                    <div className="section2">
                        <h1 className="titulo-principal"> OFERTA ESPECIAL</h1>
                        <div className="row-landing">
                            <div className="column30">1 clase de prueba <span>GRATIS</span></div>
                            <div className="column30">4 clases <span>100%</span> personalizadas</div>
                            <div className="column30"><span>30% Dto.</span> en el pago del primer mes</div>
                        </div>
                        <div className="row-landing">
                            <div className="column100">
                                <span>1 clase grupal a la semana </span> de: Canto, Danza, Arte, Gimnasia Rítmica o Producción musical
                            </div>
                        </div>
                    </div>
                    <div className="mainButton" onClick={()=>onHandleModal()}>
                    </div>
                    <div className="white center1 text-medium margin bold">
                        * Solo para las primeras 15 personas 
                    </div>
                    <div>
                        <img className="imagenCelu" src={Fondo2}></img>
                    </div>
                    {shownotification && promoActiva &&
                        <Example notification={()=>callBack("", nametoShow + " y "+(1100+aleatory100())+" estan viendo esta promoción", 3000, "warning")}/>
                    }
                    {shownotification1 && promoActiva &&
                        <Example notification={()=>callBack("", nametoShow1 + " ha reservado la clase de prueba", 3000, "success")}/>
                    }
        </div>
        <div className="landing-container2">
            <h1 className="titulo-principal2"> Haz de tus hijos los mejores artistas</h1>
            <h1 className="subtitulo-principal2"> Reserva hoy mismo tu clase de prueba <span className="bold">GRATIS!</span></h1>
                <div className="section3">
                    <div className="column2">
                            <img src={Nino2} />
                        </div>
                        <div className="column2Text">
                            <p>
                                Escoge el instrumento de tu preferencia y recibe <span className="blue bold">4 clases 100% personalizadas con un 30% de descuento </span> 
                                en el pago del primer mes. Adémas te incluimos una clase grupal a la semana de: Canto, Danza, Arte, Gimnasia, Ritmica o Pre-Produccion Musical. 
                            </p>
                            <p>
                                <span className="bold">Estimula el potencial de tus hijos y juntos descubramos sus talentos.</span>     
                            </p>        
                        </div>
                    </div>
                <div className="mainButton" onClick={()=>onHandleModal()}>
                </div>
                <div className="black center1 text-medium margin bold">
                    * Solo para las primeras 15 personas 
                </div>
        </div>
        <div className="landing-container">
            <div className="column2Final">
                    <img src={SingerGirl}></img>
            </div>
            <div className="column1Final">
                <img src={LogoMinota}></img>
                <p>
                    Somos una escuela de Música y Arte con mas de 5 años de experiencia y estamos comprometidos con la educación artística de niños, jóvenes y adultos.  
                </p>
                <p>
                    Hemos logrado en nuestros estudiantes potencializar y desarrollar el amor por la música y el arte, forjar sus habilidades e impactar en sus vidas, su familia y comunidad.
                </p>
            </div>
            <div className="mainButton marginTop" onClick={()=>onHandleModal()}></div>
            <div className="divisor">
                    <h1>Ellos han confiado en nosotros</h1>
            </div>
            <p className="center1 white text-large">
                <span>⇩</span>
            </p>
            <div className= "style-testimonios">
                <img src={Testimonios}/> 
            </div>
            <div className= "style-testimonios">
                <img src={Testimoniodos}/> 
            </div>
            <div className= "style-testimonios">
                <img src={Testimoniotres}/> 
            </div>
            <div className="containerVideos">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/uMD9upd1tR8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/_qIGL7XFfZg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            <p className="center1 white text-large bold">
                <span>¡Clases que SÍ funcionan!</span>
            </p>
            <p className="precioPromo1 center1">
                                VIRTUAL: $220.000
                                PRESENCIAL: $280.000
            </p>
            <div className="section2">
                        <h1 className="titulo-principal"> OFERTA ESPECIAL</h1>
                        <div className="row-landing">
                            <div className="column30">1 clase de prueba <span>GRATIS</span></div>
                            <div className="column30">4 clases <span>100%</span> personalizadas</div>
                            <div className="column30"><span>30% Dto.</span> en el pago del primer mes</div>
                        </div>
                        <div className="row-landing">
                            <div className="column100">
                                <span>1 clase grupal a la semana de:</span> Canto, Danza, Arte, Gimnasia Rítmica o Producción musical
                            </div>
                        </div>
            </div>
            <div className="mainButton" onClick={()=>onHandleModal()}>
            </div>
            <div className="white center1 text-medium margin bold">
                        * Solo para las primeras 15 personas 
            </div>
            <div className="divisorwhite">
                <h3>📍 UBICACION </h3>
                <span>Bogota-Colombia</span>
            </div>
        </div>
        <SimpleModal openModal={openModalInscripcion}/>
        </div>
    </div>
    )};
export default LandingContainer1;