import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
//import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
//import MuiDialogContent from '@material-ui/core/DialogContent';
//import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import colors from './utils/colors';
import popUpModal from '../images/PopUP.png';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
    modal: {
        //backgroundColor: 'black',
        backgroundSize: 'cover',
        [theme.breakpoints.up('sm')]: { 
          backgroundSize: '100%',
        }
    },
    modalBackground: {

    },
    imagen: {
        width: '100%',
    },
    button: {
        color: theme.palette.getContrastText('#f8aa00'),
        backgroundColor: '#f8aa00',
        width: '100%',
        '& :hover': {
          backgroundColor: colors.purple
        }
        
    },
    mainBackGround: {
      width: '100%',
      maxWidth: '500px'
    },
    mainString: {
      color: 'white',
      backgroundColor: 'rgb(139,0,139,0.5)'
    },
    mainGratis: {
      color: colors.yellow,
      fontWeight: 'bold',
      backgroundColor: 'rgb(139,0,139,0.5)',
      filter: 'drop-shadow( 3px 3px 4px rgba(0, 0, 0, 0.4))'
    },
    mainBack: {
      backgroundColor: 'rgb(1,1,1,0.4)',
    }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// const DialogContent = withStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

function CustomizedDialogs(props) {
    const classes = useStyles();
   // const isUpSmallBreakPoint = isWidthUp('sm', props.width);
   // const extraSmall = window.innerWidth < 330 ? "h3": "h2";
    return (
    <div>
    <Dialog  className={classes.mainBack} onClose={props.handleClose} 
    aria-labelledby="customized-dialog-title" 
    open={props.open}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      },
    }}>
    <DialogTitle id="customized-dialog-title" className={classes.modalBackground}  onClose={props.handleClose}>
    </DialogTitle>
    <div className={classes.modalBackground} onClick={()=> {props.openFormulario();props.handleClose();}}>
    <img className={classes.imagen} src={popUpModal} alt="Clase Gratis"/>
        {/* <div className = {classes.modal}>
        <DialogContent dividers>
          <div className = {classes.mainBackGround}> 
            <Typography className={classes.mainString} variant="h3">Agenda tu clase de prueba.</Typography>
            <Typography className={classes.mainGratis} variant={extraSmall}>¡Gratis 🥳!</Typography>
          </div>
        </DialogContent>
        <DialogActions>
        <Button className={classes.button} onClick={()=> {props.openFormulario();props.handleClose();}} color="primary">
          <Typography variant="body1">AGENDAR CITA AHORA</Typography>
        </Button>
        </DialogActions>
        </div> */}
      </div>
    </Dialog>
    </div>
    )
}

export default CustomizedDialogs;