import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import YTReady from '../utils/youtubeAPI.js';

const useStyles = makeStyles((theme) => ({
        containerVideo: {
            position: 'relative',
            backgroundColor: '#191619',
            paddingBottom: '56.25%',
            height: '0',
            zIndex: '-4',
            overflow: 'hidden',
            maxWidth: '100%',
            '& iframe' :{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                borderWidth: '0'
            }
        },
        showOver: {
            zIndex: '99'
        }
    })
);
const YoutubeVideo = (props) => {
    const classes = useStyles();
    const uniqueId = _.uniqueId();
    const {idCallBack} = props;

    if(window.YT && idCallBack){
    
        YTReady.then((YT) => {
            new YT.Player(`youtube-player-${props.id}${uniqueId}`, {
                videoId: props.id,
                playerVars: props.playerProperties,
                events: {
                onStateChange: (event)=>{props.handlePlay(event.data, idCallBack)}
                }
            })
        });
    }
       
    

    return ( 
        <>
        <div className={`${classes.containerVideo} ${props.showOver ? classes.showOver : ""}`} >
            {/* <iframe width="600px" height="100%" title="video intro"
                src={props.url}>
            </iframe> */}
            <div className="embed-responsive" id={`youtube-player-${props.id}${uniqueId}`}>
            </div>
        </div>
        </>
        );
}
 
export default YoutubeVideo;